import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import BlogCard from "../components/cards/blog-card";
import StatisticCard from "../components/cards/statistic-card";

import { Typography, Button, Grid } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import Collapse from "@mui/material/Collapse";

import baseStyles from "../css/const.js";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import DefaultLayout from "../components/layouts/default-layout";
import useTracker from "../hooks/use-tracker";
import useDocTitle from "../hooks/use-doc-title";
import UserService from "../services/api/user-service";
import SolutionService from "../services/api/solution-service";
import ScenarioService from "../services/api/scenario-service";
import ToolService from "../services/api/tool-service";
import NewsService from "../services/api/news-service";

export default function Index() {
  useDocTitle("CommonRoad");

  useTracker();

  const classes = baseStyles();

  const [showNews, setShowNews] = React.useState(false);
  const [showTools, setShowTools] = React.useState(false);
  const [userCount, setUserCount] = React.useState(1029);
  const [scenarioCount, setScenarioCount] = React.useState(5705);
  const [submissionCount, setsubmissionCount] = React.useState(239632);
  const [tools, setTools] = React.useState([]);
  const [news, setNews] = React.useState([]);

  const renderNewButton = () => {
    if (news.length > 4) {
      return (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowNews(!showNews)}
            className={classes.button}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            View {showNews ? "Less" : "More"}
          </Button>
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };

  React.useEffect(() => {
    UserService.totalUserCount().then((response) => {
      setUserCount(response.data.count);
    });
    ScenarioService.getScenarioCount().then((response) => {
      setScenarioCount(response.data);
    });
    SolutionService.totalSolutionCount().then((response) => {
      setsubmissionCount(response.data.count);
    });
    ToolService.getTools().then((response) => {
      setTools(response.results);
    });
    NewsService.getNews().then((response) => {
      setNews(response.data);
    });
  }, []);

  return (
    <DefaultLayout>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        spacing={3}
      >
        {/** Competition 2023 Announcements */}
        {/* <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            Competition 2023 deadlines were updated. Please check out
            <Link to={"/competition/2023/announcement"}>
              {" "}
              Announcement ITSC 2023
            </Link>
          </Typography>
        </Grid> */}
        {/** Welcome */}
        <Grid item>
          <Video
            controls={[
              "PlayPause",
              "Seek",
              "Time",
              "Volume",
              "Fullscreen",
              "Res",
            ]}
          >
            <source
              src="https://nextcloud.in.tum.de/index.php/s/RBEr2iNbtfp9TxK/download/Edgar%20.mov"
            />
          </Video>
          <Paper className={classes.paper} square={true}>
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              spacing={3}
              alignItems="center"
            >
              <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
                <Video
                  controls={[
                    "PlayPause",
                    "Seek",
                    "Time",
                    "Volume",
                    "Fullscreen",
                    "Res",
                  ]}
                  poster={
                    require("../assets/video/CR_intro_video_still.png").default
                  }
                >
                  <source
                    src={require("../assets/video/CR_intro_video.mp4").default}
                    type="video/mp4"
                  />
                </Video>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{
                  maxWidth: "700px",
                  paddingLeft: "25px",
                  paddingRight: "20px",
                }}
              >
                <Typography className={classes.subtitle}>
                  Introduction
                </Typography>
                <Typography align="left" style={{ marginTop: "8px" }}>
                  CommonRoad is a collection of composable benchmarks for motion
                  planning on roads, which provides researchers with a means of
                  evaluating and comparing their motion planners. A benchmark
                  consists of a scenario with a planning problem, a vehicle
                  dynamics model, vehicle parameters, and a cost function
                  composing a unique ID. Along with the benchmarks, we provide
                  several tools for motion planning. For further information,
                  see our{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://mediatum.ub.tum.de/doc/1379638/776321.pdf"
                  >
                    paper
                  </a>{" "}
                  or <Link to={"/getting-started"}>getting started</Link>.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/** Scenarios */}
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            Scenarios and Benchmarks
          </Typography>
        </Grid>

        <Grid item>
          <Paper className={classes.paper} square={true}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={4} style={{ textAlign: "left" }}>
                <Typography>
                  Our scenario database is a continuously growing collection of
                  naturalistic datasets, handcrafted, and automatically
                  generated scenarios provided in our XML format. For
                  visualizations and a filter for selecting scenarios,{" "}
                  <Link to={"/scenarios"}>see here</Link>. Submitted solutions
                  to planning problems are ranked in the{" "}
                  <Link to={"/solutions/ranking/"}>benchmark leaderboard</Link>.
                </Typography>
              </Grid>
              {/*<Grid item xs={12} sm={6} md={3}  style={{textAlign: "center"}}>*/}
              {/*  <img style={{width:"100%", maxWidth:"300px"}} src={require("../assets/27494_KS2_WX1_RUS_Bicycle-6_2_T-1_2018b.gif")} />*/}
              {/*</Grid>*/}
              <Grid item xs={6} sm={6} md={3} style={{ textAlign: "center" }}>
                <img
                  style={{ width: "90%", maxWidth: "100%" }}
                  alt="scenario"
                  src={
                    require("../assets/29615_KS2_WX1_USA_US101-8_2_T-1_2018b.gif")
                      .default
                  }
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3} style={{ textAlign: "center" }}>
                <img
                  style={{ width: "90%", maxWidth: "100%" }}
                  alt="scenario"
                  src={
                    require("../assets/21441_KS2_WX1_USA_Lanker-1_3_T-1_2018b.gif")
                      .default
                  }
                />
              </Grid>

              <Hidden lgDown>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  style={{ textAlign: "center" }}
                >
                  <img
                    style={{ width: "63%", maxWidth: "100%" }}
                    alt="scenario"
                    src={
                      require("../assets/11769_KS2_SM1_USA_Peach-3_3_T-1_2018b.gif")
                        .default
                    }
                  />
                </Grid>
              </Hidden>
            </Grid>

            <Grid item container alignItems={"center"} spacing={2}>
              <Grid item xs={6} sm={4}>
                <StatisticCard text="Users" count={userCount}></StatisticCard>
              </Grid>

              <Grid item xs={6} sm={4}>
                <StatisticCard
                  text="Scenarios"
                  count={scenarioCount}
                  to="/scenarios"
                ></StatisticCard>
              </Grid>

              <Grid item xs={12} sm={4}>
                <StatisticCard
                  text="Benchmark submissions"
                  count={submissionCount}
                  to="/solutions/ranking"
                ></StatisticCard>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/** News */}
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>News</Typography>
        </Grid>

        <Grid item container spacing={2}>
          {news.slice(0, 3).map(function (item) {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <BlogCard
                  title={item.title}
                  text={item.description}
                  subheader={item.date}
                  href={item.link}
                ></BlogCard>
              </Grid>
            );
          })}
        </Grid>

        <Grid item>
          <Collapse in={showNews}>
            <Grid container spacing={2}>
              {news.slice(3).map(function (item) {
                return (
                  <Grid item xs={12} sm={6} md={4}>
                    {/*<Collapse in={showNews}>*/}
                    <BlogCard
                      title={item.title}
                      text={item.description}
                      subheader={item.date}
                      href={item.link}
                    />
                    {/*</Collapse>*/}
                  </Grid>
                );
              })}
            </Grid>
          </Collapse>
        </Grid>

        {renderNewButton()}

        {/** Tools */}
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>Tools</Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              {tools.slice(0, 3).map(function (item) {
                return (
                  <Grid item xs={12} sm={6} md={4}>
                    <BlogCard
                      title={item.title}
                      subheader={item.pypi_installation}
                      text={item.short_description}
                      href={`/tools/${item.href}`}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item>
            <Collapse in={showTools}>
              <Grid container spacing={2}>
                {tools.slice(3).map(function (item) {
                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <BlogCard
                        title={item.title}
                        subheader={item.pypi_installation}
                        text={item.short_description}
                        href={`/tools/${item.href}`}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowTools(!showTools)}
            className={classes.button}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            View {showTools ? "Less" : "More"}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Forum</Typography>
        </Grid>

        {/** Forum */}
        <Grid item xs={12}>
          <Typography
            variant="body1"
            align="center"
            style={{ fontWeight: "500" }}
          >
            Visit our forum to get help or{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfq3ygJdwpIPDMoRjjUkSvyp-6IKd96FdPA0ogy7obq9ODprg/viewform"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              give feedback
            </a>
            .
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_FORUM_URL}
          >
            <Button
              variant="contained"
              className={classes.button}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              Visit
            </Button>
          </a>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
