import React from "react";
import Markdown from "react-markdown";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InfoPaper from "../../components/cards/info-card";
import ReasearchCard from "../../components/cards/research-card";
import baseStyles from "../../css/const";
import Paper from "@mui/material/Paper";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { Button, Card, Collapse } from "@mui/material";
import CodeBlock from "../../components/markdown/code-block";
import { renderVideo } from "../../components/cards/video";
import ToolService from "../../services/api/tool-service";
import _ from "lodash";

export function ExampleCode({ source, ...rest }) {
  return (
    <Markdown
      className={rest.className}
      source={source}
      renderers={{ code: CodeBlock }}
    />
  );
}

function ExpandCollapseButton(props) {
  const classes = baseStyles();

  return (
    <Button
      className={classes.button}
      variant="contained"
      onClick={props.onClick}
    >
      View {props.expand ? "More" : "Less"}
    </Button>
  );
}

function Description({ source, picture, ...rest }) {
  return (
    <Markdown
      className={rest.className}
      source={source}
      renderers={{ code: CodeBlock }}
    />
  );
}

function Picture({ src }) {
  if (src) {
    return (
      <img
        src={src}
        alt="general_picture"
        style={{ maxWidth: "90%", maxHeight: "550px" }}
      />
    );
  }

  return null;
}

function Section({ type, title, content }) {
  const classes = baseStyles();
  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>{title}</Typography>
      </Grid>

      {content.map((item) => {
        if (type === "links") {
          const props = {
            title: item.title,
            link: item.link,
            description: item.description,
          };

          return (
            <Grid item xs={12} sm={4}>
              <InfoPaper {...props} />
            </Grid>
          );
        } else if (type === "tutorials") {
          const props = {
            title: `Tutorial ${item.getting_started_order}: ${item.title}`,
            link: `/tutorials/${item.link}`,
            description: item.description,
            bg_image: item.background_image,
          };

          return (
            <Grid item sm={6} md={4}>
              <InfoPaper {...props} />
            </Grid>
          );
        } else {
          const props = {
            title: item.title,
            authors: item.authors,
            BibTex: item.BibTex,
            more: item.more,
            fullText: item.fullText,
          };

          return (
            <Grid
              item
              sm={12}
              md={0}
              alignContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <ReasearchCard {...props} />
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default function Tool(props) {
  const tool = props.match.params.tool;

  const [showMoreVideos, setShowMoreVideos] = React.useState(false);
  const [toolInfo, setToolInfo] = React.useState({});

  React.useEffect(() => {
    ToolService.getTool({ href: tool })
      .then((response) => {
        setToolInfo(response.results[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tool]);

  const classes = baseStyles();

  useDocTitle(toolInfo.title);
  useTracker();

  if (Object.keys(toolInfo).length === 0 || toolInfo === undefined) {
    return <div />;
  }

  const width = toolInfo.picture ? 8 : 12;

  // group tutorials
  const tutorials = _.chain(toolInfo.tutorials)
    .orderBy(["getting_started_order"], ["asc"])
    .groupBy("getting_started")
    .entries()
    .map(([key, value]) => {
      const mapping = {
        true: "getting_started",
        false: "advanced",
      };

      return [mapping[key], value];
    })
    .fromPairs()
    .value();

  return (
    <body className={classes.body}>
      <DefaultLayout>
        <main>
          <Grid container justifyContent="left" spacing={2}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Grid item container alignItems="stretch" spacing={1}>
                {/* title starts */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.title}>
                      {toolInfo.title}
                    </Typography>
                    {
                      toolInfo.banner_image &&
                      <img src={toolInfo.banner_image} alt="banner" style={{ maxHeight: '75px' }} />
                    }
                  </Box>
                </Grid>
                {/* title ends */}

                {/* description starts */}
                <Grid item xs={12} md={width}>
                  <Description
                    className={classes.markdown_text}
                    source={toolInfo.text}
                  />
                </Grid>
                {/* description ends */}

                {/* picture starts */}
                {
                  <Grid item ms={12} md={4} style={{ textAlign: "center" }}>
                    <Picture src={toolInfo.picture} />
                  </Grid>
                }
                {/* picture ends */}

                {/* installation starts */}
                {toolInfo.pypi_installation && (
                  <Grid item xs={12} md={12}>
                    <Box style={{ fontWeight: "bold" }}>Installation:</Box>
                    <ExampleCode
                      source={
                        " ```python\n" +
                        `${toolInfo.pypi_installation}\n` +
                        "```"
                      }
                      text
                    />
                  </Grid>
                )}
                {/* installation ends */}

                {/* code example starts */}
                {toolInfo.code && (
                  <Grid item xs={12}>
                    <ExampleCode
                      className={classes.markdown_text}
                      source={toolInfo.code}
                    />
                  </Grid>
                )}
                {/* code example ends */}
              </Grid>
            </Paper>

            {toolInfo.videos.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>Videos</Typography>
                </Grid>

                {/* videos starts */}
                <Grid item container spacing={2}>
                  {toolInfo.videos.map((video) => {
                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        {renderVideo(video)}
                      </Grid>
                    );
                  })}
                  {/* videos ends */}

                  {/* video collapse starts */}
                  {toolInfo.videos.length > 2 && (
                    <Grid item xs={12}>
                      <Collapse in={showMoreVideos}>
                        <Grid container spacing={2}>
                          {toolInfo.videos.map((video) => {
                            return (
                              <Grid item xs={12} sm={12} md={6}>
                                <Paper square={true}>
                                  {renderVideo(video)}
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Collapse>
                    </Grid>
                  )}
                  {/* video collapse ends */}
                </Grid>
              </>
            )}

            {/* expand-collapse button of videos */}
            {toolInfo.videos.length > 2 && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <ExpandCollapseButton
                  expand={!showMoreVideos}
                  onClick={() => setShowMoreVideos((old) => !old)}
                />
              </Grid>
            )}

            {/* tutorials start */}
            {Object.keys(tutorials).length > 0 && (
              <React.Fragment>
                {"getting_started" in tutorials &&
                  tutorials.getting_started.length > 0 && (
                    <Grid item xs={12}>
                      <Section
                        type="tutorials"
                        content={tutorials.getting_started}
                        title="Getting Started"
                      />
                    </Grid>
                  )}

                {"advanced" in tutorials && tutorials.advanced.length > 0 && (
                  <Grid item xs={12}>
                    <Section
                      type="tutorials"
                      content={tutorials.advanced}
                      title="Advanced"
                    />
                  </Grid>
                )}
              </React.Fragment>
            )}
            {/* tutorials end */}

            {/* links section starts */}
            {toolInfo.links.length > 0 && (
              <Grid item xs={12}>
                <Section type="links" content={toolInfo.links} title="Links" />
              </Grid>
            )}
            {/* links section ends */}

            {/* publications section starts */}
            {toolInfo.papers.length > 0 && (
              <Grid item xs={12}>
                <Section
                  type="publications"
                  content={toolInfo.papers}
                  title="Publications"
                />
              </Grid>
            )}
            {/* publication section ends */}
          </Grid>
        </main>
      </DefaultLayout>
    </body>
  );
}
